import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ProductDataResult } from '../data/ProductData';
import _products from "../data/products.json";
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { Snackbar, TextField, Select, MenuItem, InputLabel, Button, FormHelperText, FormControl, ButtonGroup } from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { INITIAL_ALERT } from '../components/Toast';
import { API_BASE_URL } from '../api/config.json'
import { Stack } from "@fluentui/react";
import { Customers, Customer } from '../data/Customer';
import { useTranslation } from 'react-i18next';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

type Asset = {
    title: string;
    assetid: string;
    serial: string;
    customerIdent: string;
    location: string;
    address: string;
    brand: string;
    product: string;
    freefield1:string;
    customer: Customer | null;
    note: string;
};

const defaultValues: Asset = { title: "", assetid: "", brand: "", product: "", serial: "", customerIdent: "", location: "", address: "", customer: null, note: "", freefield1:"" };

export function AddAssetForm() {

    const [inEdit, setEdit] = useState(false);
    const [customers, setCustomers] = useState<Customers>([]);
    const { register, handleSubmit, setValue, getValues, errors, control, reset } = useForm({ defaultValues });
    const [alertData, setAlertData] = useState(INITIAL_ALERT);
    const productData: ProductDataResult = _products;
    const uniqueBrands = productData.filter((v, i, a) => a.findIndex(t => (t.brand === v.brand)) === i).sort((a, b) => a.brand > b.brand ? 1 : -1);
    const [product, setProduct] = useState<ProductDataResult>([]);
    const [updAssetId, setUpdateAssetId] = useState('');
    const { t, i18n } = useTranslation();    

    useEffect(() => {
        document.title = t("addAsset");

        fetch(`${API_BASE_URL}/api/customer`)
            .then(response => response.json())
            .then(data => setCustomers(data))
            .catch(error => setAlertData(error));
    }, []);

    const onBrandChanged = (newBrand: any) => {
        setProduct(productData.filter(e => e.brand === newBrand));
    };

    const onAssetIdChanged = (asset: string) => {
        fetch(`${API_BASE_URL}/api/ServiceAssets/${asset}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    setEdit(true);
                    setUpdateAssetId(asset);
                    response.json()
                        .then(data => {
                            const fields = ['product', 'serial', 'customerIdent', 'location', 'address', 'note', 'freeField1'];
                            onBrandChanged(data['brand']);
                            setValue('brand', data['brand'])
                            setValue('customer', customers.find((v) => v.id === data['customer']));
                            fields.forEach(field => setValue(field, data[field]));
                        })
                }
                else if (response.status === 404) {
                    // setEdit(false);
                    // setUpdateAssetId('');
                    // reset({ address: '', brand: '', customer: null, customerIdent: '', note: '', title: '', location: '', product: '', serial: '' });
                    // return Promise.reject('error 404')
                }
            })

            .catch(error => setAlertData(prevState => ({ ...prevState, open: true, say: "Error when looking for Asset Id", severity: "error", timeout: 6000 })))
    }

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertData(prevstate => ({ ...prevstate, open: false }));
    };

    // Send indhold af form
    const onSubmit = (data: Asset, e: any) => {
        console.log("Sender data", data);
        var url;

        inEdit ? url = `${API_BASE_URL}/api/ServiceAssets/${updAssetId}` : url = `${API_BASE_URL}/api/ServiceAssets`

        fetch(
            url, {
            method: inEdit ? "PUT" : "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 409) {
                        setAlertData(prevState => ({ ...prevState, open: true, say: t("assetIdExists"), severity: "error", timeout: 6000 }));
                    }
                }
                else {
                    reset();
                    setAlertData(prevState => ({ ...prevState, open: true, say: `${t("assetId")} ${data.assetid} ${t("created")}`, severity: "success", timeout: 6000 }));
                    setEdit(false);
                }
            }).catch(e => {
                setAlertData(prevState => ({ ...prevState, open: true, say: t("criticalError"), severity: "error", timeout: 6000 }));
            });

            reset();
    };

    const onReset=()=>{
        reset();
        setEdit(false);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <Stack>
                <TextField
                    required
                    id="assetid"
                    // disabled={inEdit}
                    name="assetid"
                    label={t("assetId")}
                    onBlur={e => { onAssetIdChanged(e.target.value) }}
                    inputRef={register({ required: true })}
                    error={errors.assetid && errors.assetid.type === "required"}
                    InputLabelProps={{
                        shrink: true,
                    }}
                >
                </TextField>

                <FormControl>
                    <TextField
                        required
                        id="serial"
                        name="serial"
                        label={t("serialNumber")}
                        inputRef={register({ required: true })}
                        error={errors.serial && errors.serial.type === "required"}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    >
                    </TextField>
                </FormControl>

                <FormControl>
                    <TextField
                        id="location"
                        name="location"
                        label={t("location")}
                        inputRef={register}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    >
                    </TextField>
                </FormControl>

                <FormControl>
                    <TextField
                        id="address"
                        name="address"
                        label={t("address")}
                        inputRef={register}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    >
                    </TextField>
                </FormControl>

                <FormControl>
                    <TextField
                        id="customerIdent"
                        name="customerIdent"
                        label={t("customerIdent")}
                        inputRef={register}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    >
                    </TextField>
                </FormControl>

                <FormControl>
                    <TextField
                        id="freeField1"
                        name="freeField1"
                        label={t("freefield1")}
                        inputRef={register}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    >
                    </TextField>
                </FormControl>

                <FormControl>
                    <Controller
                        render={props => (
                            <Autocomplete
                                {...props}
                                autoSelect
                                disabled={inEdit}
                                autoHighlight
                                options={customers as Customers}
                                getOptionLabel={(option) => (`${option.name}`)}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        label={t("customer")}
                                        variant="standard"
                                        inputProps={{ ...params.inputProps, autoComplete: 'new-password', }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} />
                                )}
                                onChange={(_, value) => props.onChange(value)}
                            />
                        )}
                        name="customer"
                        rules={{ required: t<string>("customerRequired") }}
                        control={control}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.brand)}
                >
                    <InputLabel id="brand-label">{t("brand")} *</InputLabel>
                    <Controller
                        render={({ onChange, value, name }) => (
                            <Select
                                name={name}
                                disabled={inEdit}
                                value={value}
                                labelId="brand-label"
                                onChange={(e) => { onChange(e); onBrandChanged(e.target.value); }}
                            >
                                <MenuItem key="" value="" selected></MenuItem>
                                {
                                    uniqueBrands.map(item => (
                                        <MenuItem key={item.brand} value={item.brand}>{item.brand}</MenuItem>
                                    ))
                                }
                            </Select>)}
                        name="brand"
                        rules={{ required: t<string>("brandRequired") }}
                        control={control}
                    />
                    <FormHelperText>
                        {errors.brand && errors.brand.message}
                    </FormHelperText>
                </FormControl>

                <FormControl
                    error={Boolean(errors.product)}
                >
                    <InputLabel id="product-label">{t("product")} *</InputLabel>
                    <Controller
                        render={({ onChange, value, name }) => (
                            <Select
                                name={name}
                                value={value}
                                disabled={inEdit}
                                onChange={(e) => { onChange(e) }}
                            >
                                <MenuItem key="" value="" selected></MenuItem>
                                {
                                    product.map(item => (
                                        <MenuItem key={item.product} value={item.product}>{item.product}</MenuItem>
                                    ))
                                }
                            </Select>)}
                        name="product"
                        rules={{ required: t<string>("productRequired") }}
                        control={control}
                    />
                    <FormHelperText>
                        {errors.product && errors.product.message}
                    </FormHelperText>
                </FormControl>

                <FormControl>
                    <TextField
                        id="note"
                        name="note"
                        label={t("note")}
                        inputRef={register}
                        InputLabelProps={{
                            shrink: true, 
                        }}
                        inputProps={{readOnly: true}}
                    >
                    </TextField>
                </FormControl>

                <FormControl>
                    <ButtonGroup variant="contained" color="primary" fullWidth={true}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            startIcon={<SaveIcon></SaveIcon>}
                        >{inEdit ? t("update")  : t("create")}
                        </Button>
                        <Button
                            type="button"
                            color="secondary"
                            onClick={()=>onReset()}
                            startIcon={<CancelIcon></CancelIcon>}
                        >{t("cancel")}</Button>
                    </ButtonGroup>
                </FormControl>
                <Snackbar open={alertData.open} autoHideDuration={alertData.timeout} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={alertData.severity}>
                        {alertData.say}
                    </Alert>
                </Snackbar>
            </Stack>
        </form >
    );
}